/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Button */
.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.dropdown-content {
  position: absolute;
  top: 35px;
  /* right: 0; or left: 0; <-- specified via <Dropdown position="right" /> (default) or "left" */
  box-shadow: 0px 0px 1px 1px rgba(204, 204, 204, 1);
  -webkit-box-shadow: 0px 0px 1px 1px rgba(204, 204, 204, 1);
  -moz-box-shadow: 0px 0px 1px 1px rgba(204, 204, 204, 1);
  background-color: #f9f9f9;
  z-index: 5;
}

/* Links inside the dropdown */
.dropdown-content-item {
  color: black;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content-item:hover {
  background-color: #ccc;
}
