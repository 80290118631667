body {
  scroll-behavior: smooth;
  overflow: auto;
}
/*html, body {
  position: fixed;
  overflow: hidden;
}*/

img {
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

::-webkit-input-placeholder {
  color: #aaa !important;
}
::-moz-placeholder {
  color: #aaa !important;
}

.justify--space-between {
  justify-content: space-between;
}

.justify--space-around {
  justify-content: space-around;
}

.justify--flex-end {
  justify-content: flex-end;
}

.justify--flex-start {
  justify-content: flex-start;
}

.justify--center {
  justify-content: center;
}

.align-items--center {
  align-items: center;
}

.align-items--start {
  align-items: flex-start;
}

.left-50-percent {
  left: 50%;
}

.icon--m {
  height: 28px !important;
  width: 28px !important;
}

.h14 {
  height: 14px !important;
}

.w14 {
  width: 14px !important;
}

.h55 {
  height: 55px !important;
}

.h40 {
  height: 40px !important;
}

.hmin55 {
  min-height: 55px !important;
}

.hfull-55 {
  height: calc(100% - 55px);
}

.h160 {
  height: 160px !important;
}
.w160 {
  width: 160px !important;
}

.wmin435 {
  min-width: 435px !important;
}

.wmax435 {
  max-width: 435px !important;
}
.wmax140 {
  max-width: 140px !important;
}
.btn--stroke--1 {
  box-shadow: inset 0 0 0 1px currentColor !important;
}

.half-body-margin-top {
  margin-top: calc(40vh - 2em);
}

.txt-jumbo {
  font-size: 6em;
  line-height: 2em;
}
.react-datepicker-wrapper {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .responsive-box {
    width: 75vw !important;
    max-width: 420px !important;
  }
}
.responsive-box {
  width: 420px;
}

.changesets-list {
  height: calc(100vh - 55px);
}
.changesets-list ul {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.about-page-height {
  height: 100vh;
}

.changesets-filters {
  height: 100vh;
}

/* animations */

.filters-enter {
  opacity: 0.01;
}

.filters-enter.filters-enter-active {
  opacity: 1;
  animation: 0.3s fadeIn ease-out;
}

.filters-leave {
  opacity: 0.0;
  height: 0px;
}

.filters-leave.filters-leave-active {
  opacity: 1;
  animation: 0.3s fadeOut ease-out;
}

.floaters-appear {
  opacity: 0.11;
}

.floaters-appear.floaters-appear-active {
  opacity: 1;
  transition: opacity .3s ease-in;
}

.floaters-enter {
  opacity: 0.1;
}

.floaters-enter.floaters-enter-active {
  opacity: 1;
  animation: 0.4s fadeIn ease-in;
}

.floaters-leave {
  opacity: 0;
}

.floaters-leave.floaters-leave-active {
  opacity: 0.0;
  transition: opacity 0.2s ease-in;
}

.map-hide-appear {
  opacity: 0.01;
}

.map-hide-appear.example-appear-active {
  opacity: 1;
  transition: opacity .3s ease-in;
}

.map-hide-enter {
  opacity: 0.01;
}

.map-hide-enter.map-hide-enter-active {
  opacity: 1;
  /*transition: opacity 500ms ease-out;*/
  animation: 0.3s fadeIn ease-out;
}

.map-hide-leave {
  opacity: 1;
}

.map-hide-leave.map-hide-leave-active {
  opacity: 0.9;
  transition: opacity 1s ease-out;
}

#geometry-map {
  position: relative;
  display: inline-block;
  height: 300px;
  width: 100%;
  z-index: 0;
}

.filters-desc-appear {
  opacity: 0.01;
}

.filters-desc-appear.example-appear-active {
  opacity: 1;
  transition: opacity .3s ease-in;
}

.filters-desc-enter {
  opacity: 0.01;
}

.filters-desc-enter.filters-desc-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.filters-desc-leave {
  opacity: 1;
}

.filters-desc-leave.filters-desc-leave-active {
  opacity: 0.1;
  transition: opacity 200ms ease-out;
}

.tippy-tooltip.osmcha-theme {
  /* Your styling here. Example: */
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  box-shadow: 1px 0px 6px 0px #ccc;
}
.tippy-tooltip.osmcha-theme [x-arrow] {
  /* Your arrow styling here. Uses transform: rotate() so no need for CSS triangle shenanigans. */
  background-color: transparent;
  border: 1px solid #ccc;
  fill: white;
  border-color: transparent transparent #ccc #ccc; /* same for any position */
}

/* truncate uptill 2 lines */

.truncate-3-lines {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (2) */
  max-height: 3.8em;
  /*min-height: 2.4em;*/
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: left;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
/* hide ... if we have text, which is less than or equal to max lines */
.truncate-3-lines:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: transparent;
}

/*react-notification-system*/
.notifications-tr {
  margin-top: 56px;
}
/* changeset map*/
.cmap-container .cmap-sidebar {
  position: fixed !important;
  bottom: 0px;
  padding: 5px;
  top: inherit !important;
  left: inherit !important;
}

.cmap-container .cmap-map {
  background: #000 !important;
}

.cmap-changeset-section, .cmap-sidebar {
  display: none !important;
}

.cmap-diff {
  position: fixed !important;
  bottom: 14px;
  right: 0px;
  padding: 5px;
  top: inherit !important;
  left: inherit !important;
  z-index: 0 !important;
}

.cmap-changeset-link, .cmap-wikidata-link {
  text-decoration: underline;
}

.txt-bold-on-hover:hover {
  font-weight: 600;
}

.txt-underline-on-hover:hover {
  text-decoration: underline;
}

.cmap-bg-create {
  background-color: #39dbc0;
}

.cmap-bg-modify-old {
  background-color: #db950a;
}

.cmap-bg-modify-new {
  background-color: #e8e845;
}

.cmap-bg-delete {
  background-color: #cc2c47;
}

.cmap-bg-create-light {
  background-color: #beefe7;
}

.cmap-bg-modify-old-light {
  background-color: #f4dfb5;
}

.cmap-bg-modify-new-light {
  background-color: #f8f8c7;
}

.cmap-bg-delete-light {
  background-color: #ebbac2;
}

/* OSMCha Custom CSS*/

.osmcha-custom-table th,
.osmcha-custom-table td {
  padding: 6px !important;
}

/* Changeset list color hacks */

.light-blue,
.light-blue-on-hover:hover {
  background-color: #eef4fa !important;
}

.changesets-list .border-color-good {
  border-color: rgba(57, 219, 192, 0.75) !important;
}

.changesets-list .border-color-bad {
  border-color: rgba(204, 44, 71, 0.75) !important;
}

.changesets-list .border-color-neutral {
  border-color: #d8d8d8 !important;
}

.changesets-list .border-l--4 {
  border-left-width: 4px !important;
}

/* bbox map*/
.bbox-map-clear {
  position: relative;
  top: 28px;
  right: 1px;
}
/*
.about-page h2 {
  font-size: 18px;
  line-height: 30px;
}

.about-page h3 {
  font-size: 14px;
  line-height: 24px;
}*/
.filters-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.txt-break-url {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.icon--xxl {
  height: 144px !important;
  width: 144px !important;
}

.user-description a {
  color: #448ee4;
}
.user-description h2 {
  font-weight: 600;
}

/*home images*/
.osmcha-logo {
  width: 35vw;
}

.workflow-img {
  width: 30vw;
}

.osmus-img {
  width: 14vw;
}

#guide > p {
  padding-top: 6px;
}

#guide > p > img {
  padding-bottom: 6px;
  margin: 0 auto;
  display: block;
  max-width: 100%;
}

.p6 {
  padding: 6px;
}

.p12 {
  padding: 12px;
}

.border--darken5 {
  border-color:rgba(0,0,0,.05) !important;
}

.w72 {
  width: 72px;
}

.h72 {
  height: 72px;
}

.w96 {
  width: 96px;
}

.h96 {
  height: 96px;
}
.line45 {
  line-height: 45px;
}

.txt-underline-dotted {
  text-decoration: underline dotted !important;
}
